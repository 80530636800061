/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/reset.css'
import './src/styles/markswatch-theme.css'
import './src/styles/gridblock.css'
import './src/styles/container.css'
import './src/styles/global.css'
import './src/styles/gatsby-remark-code-titles.css'
