// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-posts-query-js": () => import("./../src/templates/posts-query.js" /* webpackChunkName: "component---src-templates-posts-query-js" */),
  "component---src-templates-post-query-js": () => import("./../src/templates/post-query.js" /* webpackChunkName: "component---src-templates-post-query-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-case-studies-thuwannamogok-mdx": () => import("./../src/pages/case-studies/thuwannamogok.mdx" /* webpackChunkName: "component---src-pages-case-studies-thuwannamogok-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-services-mdx": () => import("./../src/pages/services.mdx" /* webpackChunkName: "component---src-pages-services-mdx" */),
  "component---src-pages-services-consulting-mdx": () => import("./../src/pages/services/consulting.mdx" /* webpackChunkName: "component---src-pages-services-consulting-mdx" */),
  "component---src-pages-services-devops-mdx": () => import("./../src/pages/services/devops.mdx" /* webpackChunkName: "component---src-pages-services-devops-mdx" */),
  "component---src-pages-certifications-mdx": () => import("./../src/pages/certifications.mdx" /* webpackChunkName: "component---src-pages-certifications-mdx" */),
  "component---src-pages-services-migrations-mdx": () => import("./../src/pages/services/migrations.mdx" /* webpackChunkName: "component---src-pages-services-migrations-mdx" */),
  "component---src-pages-services-prisma-cloud-mdx": () => import("./../src/pages/services/prisma-cloud.mdx" /* webpackChunkName: "component---src-pages-services-prisma-cloud-mdx" */),
  "component---src-pages-services-security-audit-mdx": () => import("./../src/pages/services/security-audit.mdx" /* webpackChunkName: "component---src-pages-services-security-audit-mdx" */),
  "component---src-pages-technologies-mdx": () => import("./../src/pages/technologies.mdx" /* webpackChunkName: "component---src-pages-technologies-mdx" */),
  "component---src-pages-vps-affiliate-mdx": () => import("./../src/pages/vps-affiliate.mdx" /* webpackChunkName: "component---src-pages-vps-affiliate-mdx" */),
  "component---src-pages-services-solutions-mdx": () => import("./../src/pages/services/solutions.mdx" /* webpackChunkName: "component---src-pages-services-solutions-mdx" */)
}

